th.tableHeader {
    font-weight: 550;
}

div.summaryBox {
    padding: 8px;
    margin-bottom: 8px;
    background-color: #ececec;
    display: flex;
    align-items: center;
}

.error {
    color: red;
}