@import '../../assets/styles.css';

.toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 88;
}
.grow {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  flex-grow: 1;
  z-index: 999;
}
.grow-search {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 1232px;
  padding: 0 !important;
}
.toolbar .title {
  min-width: 272px;
  text-align: left;
  padding: 16px 0;
}
.menu-button {
  margin-left: 32px;
}

.section-desktop {
  display: none;
  min-width: 272px;
}
@media (min-width:960px) {
  .section-desktop {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
.section-mobile {
  display: flex;
}
@media (min-width:960px) {
  .section-mobile {
    display: none;
  }
}
.header-logo {
  width: 97px;
  height: 32px;
}
.MuiButtonBase-root.user .MuiIconButton-label .username {
  font-size: 16px;
  margin-left: 6px;
}
.MuiButtonBase-root.user {
  margin-left: 12px;
}