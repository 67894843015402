.form_field_paper_frame {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
}

.form_field_remove_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 0 10px 10px;
}

.form_field_add_button {
    padding: 20px 0;
}

.branchSelect {
    width: 100%;
}

.contentActions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 30px;
}