.collapseContent {
    padding-bottom: 16px;
}

.page_content_title_frame {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.page_content_button_group_frame {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.page_content_button_group_frame button {
    margin-left: 30px;
}

.save_form_btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px 12px 0 12px;
    width: 100%;
}

@media (max-width:1250px) {
    .page_content_title_frame {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
}