.page_title_frame {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
}

.page_search_box_frame {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.page_search_box {
    min-width: 100px;
    padding: 0 24px;
    margin-left: 16px;
}

.tab_frame {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 12px;    
}

.tab_frame button > span > span {
    padding-top: 6px !important;
}
