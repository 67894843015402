.login_page_root {
    width: 100vw;
    height: 100vh;
    background-color: #31383D;
}

.login_page_frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: 450px;
    padding-top: 10%;
}

.login_page_frame h4 {
    font-weight: bold;
    color: #fff;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important;
}

.login_page_frame a {
    font-weight: bold;
    color: #fff;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important;
}

.login_card_frame {
    display: flex;
    flex-direction: column;
    min-width: 26%;
    justify-content: space-between;
    padding: 40px;
    margin-top: 20px;
}

.from_actions {
    display: flex;
    justify-content: space-between;
}

.login_logo {
    width: 50px;
    height: 50px;
}

@media (max-width:1025px) {
    .login_card_frame {
        min-width: 40%;
    }
}

@media (max-width:768px) {
    .login_card_frame {
        min-width: 60%;
    }
}
