.pageRoot {
    display: block;
}

.contentWrap {
    margin-top: 1em;
}

.content {
    flex: 1 1 auto;
    padding: 16px;
}

.page_title_frame {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 24px;
}

.contentActions {
    background-color: #f5f5f5;
    margin-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    min-height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.inputItem {
    width: 100%;
    min-width: 150px;
}

.deleteButton {
    margin-left: auto;
}
