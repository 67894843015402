.container {
    padding: 16px;
}

.input {
    width: 100% !important;
}

.dialog_button_group_frame {
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    margin: 16px;
}

.add_load_plan_dialog_content {
    min-width: 485px;
}