@import '../../assets/styles.css';

.titlebar.flexbox {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  line-height: 1.5;
}
.titlebar.margin-top {
  margin-top: 48px;
}

.titlebar > h2 {
  margin: 0;
}

.titlebar > .flexgrow {
  flex-grow: 1; /* default 0 */
  padding: 8px 16px 8px 16px;
  color: #ccc;
}
.titlebar > .flexgrow > hr {
  border: 1px solid #DEE1E6;
  margin-top: 11px;
}

.titlebar-children {
  display: flex;
}

.titlebar-children > label, .titlebar-children > div {
  margin-top: 6px;
}

.titlebar-children > *:not(:last-child) {
  margin-right: 10px;
}

.titlebar .selectEmpty {
  margin-top: 16px;
}

.titlebar .MuiOutlinedInput-input {
  padding: 4px 14px;
}

.titlebar .MuiOutlinedInput-root {
  border-radius: 0;
}

.titlebar-children label {
  padding: 4px;
  margin-right: 0 !important;
  border: 1px solid #DEE1E6;
  font-size: 16px;
  color: #9BA6B2;
  letter-spacing: -0.48px;
}