.pageRoot {
    display: block;
}

.contentWrap {
    margin-top: 1em;
}

.content {
    flex: 1 1 auto;
    padding: 16px;
}

.content_table_row {
    margin-top: 16px !important;
}

.page_title_frame {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0;
}

.page_title_button_group_frame {
    display: flex;
    flex-direction: row;
}

.page_title_button_group_frame  button {
    margin-right: 15px;
}

.contained_button {
    color: #fff !important;
    background-color: #717A83 !important;
}