.react_admin_page_header {
    margin: 24px 0 -52px 0 !important;
}

.react_admin_page_add_header {
    margin: 24px 0 !important;
}

.react_admin_page_filter {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}