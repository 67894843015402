@import '../../assets/styles.css';

.notice-bar-frame {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.notice-bar-title {
    display: flex;
    align-items: flex-start;
    color: var(--massey-logo-blue);
}

.notice-bar-title-text {
    padding-left: 10px;
    color: var(--massey-logo-blue);
    font-size: 20px;
}

.notice-bar-title .k-icon{
    margin-top: -3px;
    font-size: 25px;
}
