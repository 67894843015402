.ContactWrap {
    margin-bottom: 16px;
    display: inline-block;
}

.ContactTitle {
    margin: 8px;
}

.ContactInput {
    margin: 8px !important;
    width: 256px;
}