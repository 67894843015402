@import '../../assets/styles.css';

.introbar.flexbox {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  margin-top: 16px;
}
.introbar > h1 {
  margin: 0;
}

.introbar > .flexgrow {
  flex-grow: 1; /* default 0 */
  padding: 8px 0 8px 16px;
  color: #ccc;
}

.introbar-children {
  display: flex;
}

.introbar-children > *:not(:last-child) {
  margin-right: 10px;
}

.introbar .selectEmpty {
  margin-top: 16px;
}

.introbar .MuiOutlinedInput-input {
  padding: 8px 14px;
}

.introbar .MuiOutlinedInput-root {
  border-radius: 0;
}

.introbar-children label {
  padding: 8px;
  margin-right: 0 !important;
  border: 1px solid #DEE1E6;
  background: #FAFBFC;
}

.introbar-children .MuiButtonBase-root {
  padding: 8px 20px;
}

.introbar-children .MuiIconButton-root {
  padding: 4px 10px;
}