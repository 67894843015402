.headerWarpper {
    flex: 1
}

.headerLogo {
  width: 141px;
  height: 48px;
}

.spacer {
    flex: 1
}

.profile_frame {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 6px;
}