.pageRoot {
    display: block;
}

.contentWrap {
    margin-top: 1em;
}

.content {
    flex: 1 1 auto;
    padding: 16px;
}

.contentActions {
    background-color: #f5f5f5;
    margin-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    min-height: 64px;
    display: flex;
    position: relative;
    align-items: center;
}