.summary_header_frame {
    padding-bottom: 30px;
    border-bottom-style: solid;
    border-bottom-color: grey;
    border-bottom-width: 1;
}

.page_title_frame {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 24px;
}

.paper_item_frame {
    margin: 10px 10px 0 0;
    padding: 10px;
    background-color: #e6e6e6 !important;
}

.paper_important_item_frame {
    margin: 10px 10px 0 0;
    padding: 10px;
    color: #fff !important;
    background-color: #797979 !important;
}