.form_arrow_down_icon_frame {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
}

.save_form_btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-top: 30px;
}