  .header-search-bar {
    display: flex;
    align-items: center;
    max-width: 1228px;
    padding: 4px 8px 4px 0px !important;
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    text-decoration: none;
  }
  .header-search-bar input[type=text] {
    padding-left: 32px !important;
  }

  .search {
    width: calc(100% - 32px);
    position: relative;
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
    background-color: #fff;
  }

  .label-no-caret input {
    caret-color: transparent;
  }

  .search:hover {}

  @media (min-width:600px) {
    .search {
      width: auto;
      margin-left: 0;
    }
  }

  .search-icon {
    width: 32px;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    pointer-events: none;
    justify-content: center;
    color: #a8a8a8;
    background-color: #fff;
  }

  .input-root {
    color: inherit;
    width: 100%;
    text-decoration: none;
  }

  .search .MuiInput-underline:after, .search .MuiInput-underline:before {
    border-bottom: 1px solid transparent !important;
  }

  .MuiAutocomplete-endAdornment {
    display: none;
  }
  