.collapseContent {
    padding-bottom: 16px;
}

.pageRoot {
    display: block;
}

.contentWrap {
    margin-top: 1em;
}

.content {
    flex: 1 1 auto;
    padding: 16px;
}

.page_title_frame {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0;
}

.page_title_button_group_frame {
    display: flex;
    flex-direction: row;
}

.page_title_button_group_frame  button {
    margin-right: 15px;
}

.contained_button {
    color: #fff !important;
    background-color: #717A83 !important;
}

.outlined_button {
    color: #717A83 !important;
}

.page_content_button_group_frame {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.page_content_button_group_frame button {
    margin-left: 30px;
}

.page_content_title_frame {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.contentActions {
    background-color: #f5f5f5;
    margin-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    min-height: 64px;
    display: flex;
    position: relative;
    align-items: center;
}

.form_field_paper_frame {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
}

.branchSelect {
    width: 100%;
}

@media (max-width:1250px) {
    .page_content_title_frame {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
}