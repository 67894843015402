@import './assets/styles.css';

@font-face {
  font-family: 'FiraSans Medium';
  letter-spacing: 0;
  font-stretch: condensed;
  src: url(./assets/fonts/Fira_Sans/FiraSans-Medium.ttf) format('truetype')
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'FiraSans Medium', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wrapper {
  background-color: #fff;
}
.wrapper.wrapbody {
  background-color: #f7f7f7;
}
.wrapper.topborder {
  border-top: 1px solid #DEE1E6;
}
.wrapper.btmborder {
  border-bottom: 1px solid #DEE1E6;
}
.wrapper.btmborder > .container {
  padding-bottom: 0;
}

.container {
  text-align: left;
  padding: 8px;
  max-width: 100% !important;
}

.container.bodytop {
  background-color:var( --app-background);
  text-align: left;
  padding: 8px;
}

.breadcrumb {
  top: 92px;
  border-bottom: 1px solid #DEE1E6;
  z-index: 999;
}

@media (max-width: 1100px) {
  .MuiContainer-root {
      padding-left: 10px !important;
  }
}

@media (min-width: 1100px) {
  .MuiContainer-root {
      padding-left: 0px !important;
  }
}