.DropdownMenu {
    padding-left: 8px;
}

.MenuItemWrap {
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.MenuItemTitle {
    font-size: 17px;
    margin: -30px 0 0 40px;
}

.ExpandIcon {
    min-width: 0px !important;
}

