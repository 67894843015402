.input_item {
    width: 100%;
    min-width: 150px;
}

.button_group {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.report_button {
    padding: 8px 0 !important;
    min-width: 46px !important;
}