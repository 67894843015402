@import '../../assets/styles.css';

.chipbar {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important;
}

.chipbar.flexbox {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}
.chipbar > h1 {
  margin: 0;
}

.chipbar > a {
  color: var(--primary-button);
  font-size: 16px;
}

.chipbar .linksep {
  margin: 2px 8px 0 8px;
  color: #495057;
  
  font-size: 12px;
}

.chipbar-children {
  display: flex;
  flex-wrap: wrap;
}

.chipbar-children > *:not(:last-child) {
  margin-right: 10px;
}

.chipbar .selectEmpty {
  margin-top: 16px;
}

.chipbar .MuiOutlinedInput-input {
  padding: 8px 14px;
}

.chipbar .MuiOutlinedInput-root {
  border-radius: 0;
}

.chipbar-children label {
  padding: 8px;
  margin-right: 0 !important;
  border: 1px solid #DEE1E6;
}