th.tableHeader {
    font-weight: 550;
}

.pass_indicator {
    color: #00d400;
}

.warning_indicator {
    color: #ffbf00;
}