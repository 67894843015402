.form_frame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.form_title {
    font-weight: 550;
    padding: 0 30px;
}

.form_field {
    padding: 0 10px;
}

.form_select_field {
    width: 100%;
    padding-bottom: 15px !important;
}

.from_actions {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
}

.form_content {
    min-width: 50vw;
    padding: 20px;
}

.select_form_content {
    min-width: 50vw;
    padding: 35px 35px 0;
}

.form_title_content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.success_message_chip {
    padding: 10px !important;
    font-size: 16px !important;
    border-radius: 4px !important;
}

.agency_booking_box {
    display: flex;
    flex-direction: column;
    padding: 0 0 30px 0;
    width: 100%;
}

.agency_booking_box_title_frame {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.drop_zone_frame {
    display: flex;
    border-style: dotted;
    border-color: #cb0016;
    border-width: 2px;
    min-height: 100px;
    align-items: center;
    justify-content: center;
}

.drop_zone_files_frame label {
    font-size: 14px !important;
    padding-top: 15px !important;
}

.drop_zone_files_frame ul {
    margin: 0px !important;
}

.date_time_picker {
    width: 100%;
    margin-bottom: 20px !important;
}