.form_frame {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.from_actions {
    display: flex;
    justify-content: space-between;
}

.form_content {
    min-width: 50vw;
}

.form_title_content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.success_message_chip {
    padding: 10px !important;
    font-size: 16px !important;
    border-radius: 4px !important;
}