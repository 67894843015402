.label {
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 12px;
    font-family: 'FiraSans Medium';
    font-weight: 400;
    line-height: 1;
    padding-bottom: 20px;
}

.field_frame {
    padding-top: 6px;
    padding-bottom: 10px;
}