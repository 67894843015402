.columnHeaderText {
    margin-top: 4px;
    margin-bottom: 4px;
}

.textContainer > div {
  margin-top: 0px;
  margin-bottom: 0px;
}

.textContainer input {
  height: 36px;
  padding: 0px 14px;
}

.textContainer label {
  height: 36px;
  top: -6px;
}

.textContainer label[data-shrink="true"] {
  top: 0;
}

.paper_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 62px;
}