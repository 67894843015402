.AddressWrap {
    margin-bottom: 16px;
    display: inline-block;
}

.AddressTitle {
    margin: 8px;
}

.AddressInput {
    margin: 8px !important;
    width: 512px;
}

.react_admin_page_header {
    margin: 24px 0 -52px 0 !important;
}

.react_admin_page_add_header {
    margin: 24px 0 !important;
}