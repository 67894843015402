.reports_list {
    width: 100%;
}

.reports_list_title {
    background-color: #ebebeb !important;
}

.reports_list_title:hover {
    background-color: #cacaca !important;
}

.reports_sub_list {
    padding-left: 72px !important;
}

.reports_sub_list_active {
    background: #bbb !important;
}

.form_frame {
    width: 100% !important;
}

.input_field {
    width: 100% !important;
    min-width: 150px !important;
}

.form_control{
    padding: 8px 0px !important;
    min-width: 200px !important;
    max-width: 1000px !important;
}

#report-select-label{
    min-width:100% !important;
    padding:5px !important;
}

#report-select{
    min-width:100% !important;
    padding:5px !important;
}