:root {

    /* ------ Colors ------ */

    --header-background: #004A8C;
    --app-background: #ffffff;
    --body-background: #F5F6FA;
    --error-msg: #f44336;
    --error-msg-background: #ffc7c7;
    --error-msg-border: #ff0000;
    --warning-msg: #d4972d;
    --warning-msg-background: #fcf8e3;
    --warning-msg-border: #d4972d;
    --success-msg: #3c763d;
    --success-msg-background: #dff0d8;
    --success-msg-border: #3c763d;
    --table-body-text-color: #434A52;
    --table-header-text-color: #9BA6B2;
    --primary-button: #0090E9;
    --draft-button: #E4A024;
    --active-button: #52BF0A;
    --closingout-button: #0090E9;
    --complete-button: #8A6AEF;
}